import React from 'react';
import { ErrorResponse } from 'apollo-link-error';
import { RedirectWhenUnauthorized } from '../RedirectWhenUnauthorized';
import { FatalError } from '../FatalError';
import { authSession } from '../../auth-session';

type ServerWithResponseError = ErrorResponse['networkError'] & { response: { status: number } };

function isUnauthorizedRequest(error: ErrorResponse): boolean {
  const UNAUTHORIZED_CODES = [400, 401, 403];
  const serverError = error.networkError;

  return (
    (errorHasResponseStatus(serverError) &&
      UNAUTHORIZED_CODES.includes(serverError.response.status)) ||
    false
  );
}

function errorHasResponseStatus(
  error?: ErrorResponse['networkError'],
): error is ServerWithResponseError {
  return (error && 'response' in error && error.response != null) || false;
}

export const GraphqlErrorHandler: React.FC<{ error: ErrorResponse }> = ({ error }) => {
  // eslint-disable-next-line no-console
  console.error(`Graphql Error: ${error}`);

  if (isUnauthorizedRequest(error)) {
    authSession.logout();
    return <RedirectWhenUnauthorized />;
  }

  return <FatalError />;
};
