import { isValidCpf } from '../validators/cpf';

export const cpfMask = (cpf: string): string => {
  if (isValidCpf(cpf)) {
    return cpf
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  }

  return cpf;
};
