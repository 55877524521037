import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@creditas/typography';
import { Button } from '@creditas/button';
import { TextField } from '@creditas/input';
import {
  Layout,
  Divider,
  Header,
  TextFieldWrapper,
  TextFieldPhones,
  PageWrapper,
  TextFieldZipCode,
  SaveButton,
  Form,
} from './CustomerRegistration.style';
import { cpfMask, phoneMask, zipCodeMask } from '../helpers';
import { CustomerRegistrationProps } from './interfaces';
import { states } from './states';

export const CustomerRegistration: React.FC<CustomerRegistrationProps> = ({
  customer,
  isLoading,
  onSave,
}): React.ReactElement => {
  const { t } = useTranslation();

  const [form, setForm] = useState(customer);

  const handleSave = (event: FormEvent): void => {
    event.preventDefault();

    onSave(form);
  };

  const isAddressInput = (inputName: string): boolean => {
    return (
      inputName === 'streetName' ||
      inputName === 'streetNumber' ||
      inputName === 'streetComplement' ||
      inputName === 'neighborhood' ||
      inputName === 'city' ||
      inputName === 'state' ||
      inputName === 'zipCode'
    );
  };

  const updateValue = (inputName: string, newValue: { [key: string]: string }): void => {
    if (isAddressInput(inputName)) {
      setForm(previousForm => ({
        ...previousForm,
        address: { ...previousForm.address, ...newValue },
      }));
    } else {
      setForm(previousForm => ({ ...previousForm, ...newValue }));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const inputName = e.target.name;

    const newValue = {
      [inputName]: e.target.value,
    };

    updateValue(inputName, newValue);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    const inputName = e.target.name;
    const inputValue = e.target.value.trim();

    let newValue = {};

    if (inputName === 'email') {
      newValue = {
        [inputName]: inputValue.toLowerCase(),
      };
    } else {
      newValue = {
        [inputName]: inputValue,
      };
    }

    updateValue(inputName, newValue);
  };

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>): void => {
    const unmaskedValue = e.target.value.replace(/[^\d]/g, '');

    const newValue = {
      [e.target.name]: unmaskedValue,
    };

    setForm(previousForm => ({ ...previousForm, ...newValue }));
  };

  return (
    <Layout data-testid="customer-registration">
      <Header>
        <Typography variant="h4">{customer.fullName}</Typography>
        <Typography variant="support">{cpfMask(customer.cpf)}</Typography>
      </Header>

      <Form onSubmit={handleSave}>
        <PageWrapper>
          <Typography variant="h6" color="neutral08">
            {t('labels.customerInfo')}
          </Typography>

          <Divider />

          <TextFieldWrapper>
            <TextField
              label={t('labels.fullName')}
              value={form.fullName}
              name="fullName"
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                'data-testid': 'fullNameInput',
              }}
            />
            <TextField
              label={t('labels.cpf')}
              value={cpfMask(form.cpf)}
              name="cpf"
              onChange={handleChange}
              disabled
            />
            <TextField
              type="date"
              label={t('labels.birthDate')}
              value={form.birthDate}
              name="birthDate"
              onChange={handleChange}
            />
            <TextField
              label={t('labels.email')}
              value={form.email}
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                'data-testid': 'emailInput',
              }}
            />

            <TextFieldPhones>
              <TextField
                onChange={handleChangePhone}
                value={phoneMask(form.cellphone)}
                name="cellphone"
                label={t('labels.cellphoneNumber')}
              />
              <TextField
                label={t('labels.homePhoneNumber')}
                value={phoneMask(form.residentialTelephone)}
                name="residentialTelephone"
                onChange={handleChangePhone}
              />
              <TextField
                label={t('labels.businessPhoneNumber')}
                value={phoneMask(form.additionalPhone)}
                name="additionalPhone"
                onChange={handleChangePhone}
              />
            </TextFieldPhones>
          </TextFieldWrapper>

          <Typography variant="h6" color="neutral08">
            {t('labels.address')}
          </Typography>

          <Divider />

          <TextFieldZipCode>
            <TextField
              label={t('labels.cep')}
              value={zipCodeMask(form.address.zipCode)}
              name="zipCode"
              onChange={handleChange}
            />
          </TextFieldZipCode>

          <TextFieldWrapper>
            <TextField
              label={t('labels.street')}
              value={form.address.streetName}
              name="streetName"
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                'data-testid': 'streetNameInput',
              }}
            />
            <TextField
              label={t('labels.number')}
              value={form.address.streetNumber}
              name="streetNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                'data-testid': 'streetNumberInput',
              }}
            />
            <TextField
              label={t('labels.additionalAddress')}
              value={form.address.streetComplement}
              name="streetComplement"
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                'data-testid': 'streetComplementInput',
              }}
            />
            <TextField
              label={t('labels.neighborhood')}
              value={form.address.neighborhood}
              name="neighborhood"
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                'data-testid': 'neighborhoodInput',
              }}
            />
            <TextField
              label={t('labels.city')}
              value={form.address.city}
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                'data-testid': 'cityInput',
              }}
            />
            <TextField
              label={t('labels.state')}
              value={form.address.state}
              name="state"
              onChange={handleChange}
              select
            >
              {states.map(state => (
                <option key={state.value} value={state.value}>
                  {state.name}
                </option>
              ))}
            </TextField>
          </TextFieldWrapper>
        </PageWrapper>
        <SaveButton>
          <Button onClick={handleSave} isLoading={isLoading} disabled={isLoading}>
            {t('labels.save')}
          </Button>
        </SaveButton>
      </Form>
    </Layout>
  );
};
