import React, { useState, useEffect, useRef } from 'react';

import { useToaster, ToasterMessage } from '@creditas/toaster';
import { Typography } from '@creditas/typography';
import { UseToastMessage, ToastMessage } from './interfaces';

const Message: React.FC<{ title: string; subTitle?: string }> = ({ title, subTitle }) => {
  return (
    <ToasterMessage data-testid="toast-message">
      <Typography variant="form">{title}</Typography>
      {!!subTitle && <Typography variant="support">{subTitle}</Typography>}
    </ToasterMessage>
  );
};

const useToastMessage = (): UseToastMessage => {
  const { push } = useToaster();
  const prevMessageId = useRef<string>('');
  const [ids, setIds] = useState<Array<string>>([]);
  const [message, pushMessage] = useState<ToastMessage>();

  useEffect(() => {
    if (message) {
      const { id, title, subTitle, delay, level } = message;
      const isNewUniqueMessage = !ids.includes(id);
      if (isNewUniqueMessage) {
        push(<Message title={title} subTitle={subTitle} />, {
          delay: delay || 5000,
          level,
        });
        prevMessageId.current = id;
        setIds([id, ...ids]);
      }
    }
  }, [push, message, ids]);

  return { messageIds: ids, pushMessage };
};

export { useToastMessage };
