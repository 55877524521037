import { ApolloLink } from 'apollo-link';
import { v4 as uuidv4 } from 'uuid';

export const addIdToRequest = new ApolloLink((operation, forward) => {
  const requestId = uuidv4();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Request-Id': requestId,
    },
  }));

  return forward(operation);
});
