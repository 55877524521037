import * as Sentry from '@sentry/browser';
import { ApolloError } from 'apollo-client';
import moment from 'moment-timezone';
import { envVars } from '../../envVars';
import { UseErrorHandler, RegisterErrorInput } from './interfaces';
import { useToastMessage } from '../useToastMessage';

const useErrorHandler = (): UseErrorHandler => {
  const { REACT_APP_ENV } = envVars;
  const { pushMessage } = useToastMessage();

  const registerError = ({ name, error, title, subTitle }: RegisterErrorInput): void => {
    if (REACT_APP_ENV !== 'test' && REACT_APP_ENV !== 'development') {
      Sentry.withScope(scope => {
        scope.setExtra('name', name);
        Sentry.captureException(error);
      });
      Sentry.flush(2000);
    } else if (REACT_APP_ENV === 'test' && !(error instanceof ApolloError)) throw error;

    pushMessage({
      id: moment()
        .toDate()
        .toString(),
      title,
      subTitle,
      delay: 5000,
      level: 'danger',
    });
  };

  return { registerError };
};

export { useErrorHandler };
