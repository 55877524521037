import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ErrorResponse } from 'apollo-link-error';
import { FeedbackMessage } from '@creditas/feedback-message';
import {
  BFFCustomerQuery,
  useCustomerQuery,
  useUpdateCustomerProfileMutation,
} from '../services/api/bff/types';
import { CustomerRegistration } from './CustomerRegistration';
import { CustomerRegistrationData } from './interfaces';
import { extractPhoneCode, extractPhoneNumber, formatDateStringToDate } from '../helpers';
import { mapCustomer } from './mapCustomer';
import { useErrorHandler, useToastMessage } from '../hooks';
import { Loader } from '../components/Loader';
import { GraphqlErrorHandler } from '../components/GraphqlErrorHandler';

const CustomerRegistrationWrapper: React.FC<BFFCustomerQuery> = ({
  customerByPersonId: customer,
}) => {
  const [updateCustomerProfile] = useUpdateCustomerProfileMutation();
  const { pushMessage } = useToastMessage();
  const { t } = useTranslation();
  const { registerError } = useErrorHandler();
  const [isLoading, setLoading] = useState(false);

  const mappedCustomer = mapCustomer(customer);

  const handleSave = async (data: CustomerRegistrationData): Promise<void> => {
    setLoading(true);

    try {
      const response = await updateCustomerProfile({
        variables: {
          ...data,
          customerId: data.customerId,
          fullName: data.fullName,
          email: data.email,
          birthDate: formatDateStringToDate(data.birthDate),
          cellphoneCode: extractPhoneCode(data.cellphone),
          cellphone: extractPhoneNumber(data.cellphone),
          residentialTelephoneCode: extractPhoneCode(data.residentialTelephone),
          residentialTelephone: extractPhoneNumber(data.residentialTelephone),
          additionalPhoneCode: extractPhoneCode(data.additionalPhone),
          additionalPhone: extractPhoneNumber(data.additionalPhone),
          address: {
            streetName: data.address.streetName,
            streetNumber: data.address.streetNumber,
            streetComplement: data.address.streetComplement,
            zipCode: data.address.zipCode,
            city: data.address.city,
            state: data.address.state,
            neighborhood: data.address.neighborhood,
            country: data.address.country,
          },
        },
      });

      if (response.data?.updateCustomerProfile.success) {
        pushMessage({
          id: 'updateCustomerProfileSuccess',
          title: t('feedbacks.updateCustomerProfile.success'),
          level: 'success',
        });
      } else if (response.data && response.data.updateCustomerProfile.message) {
        throw new Error(response.data.updateCustomerProfile.message);
      } else {
        const errorMessage =
          response.errors?.map(error => error.message).join('\n') ||
          'Failed to update customer profile';

        throw new Error(errorMessage);
      }
    } catch (error) {
      if (error instanceof Error) {
        registerError({
          name: 'updateCustomerProfile',
          error,
          title: t('feedbacks.updateCustomerProfile.error'),
          subTitle: t('texts.tryAgain'),
        });
      } else {
        registerError({
          name: 'updateCustomerProfile',
          error: new Error(`Unknown error. Thrown object: ${JSON.stringify(error)}`),
          title: t('feedbacks.updateCustomerProfile.error'),
          subTitle: t('texts.tryAgain'),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomerRegistration customer={mappedCustomer} isLoading={isLoading} onSave={handleSave} />
  );
};

export const CustomerRegistrationContainer: React.FC = () => {
  const { t } = useTranslation();

  const { personId } = useParams<{ personId: string }>();

  let content: React.ReactElement;

  const { data, loading, error } = useCustomerQuery({
    variables: {
      personId,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    content = <Loader />;
  } else if (error) {
    content = <GraphqlErrorHandler error={(error as unknown) as ErrorResponse} />;
  } else if (!data) {
    content = <FeedbackMessage variant="danger">{t('texts.customerNotFound')}</FeedbackMessage>;
  } else {
    content = <CustomerRegistrationWrapper customerByPersonId={data.customerByPersonId} />;
  }

  return content;
};
