import React, { Component, ReactElement, Suspense } from 'react';
import { ThemeProvider } from '@creditas/stylitas';
import tokens from '@creditas/tokens';
import { Global } from '@creditas/global';
import { AppProps } from 'single-spa';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToasterProvider } from '@creditas/toaster';
import { AppCustomProps, defaultCustomProps } from '.';
import { Routes } from './Routes';
import { CustomPropsProvider } from './contexts';
import { client } from './services/api/bff/client';
import { globalStyles } from './App.style';

/* eslint-disable react/prefer-stateless-function */
export class App extends Component<AppCustomProps & AppProps> {
  render(): ReactElement {
    return (
      <CustomPropsProvider value={{ ...defaultCustomProps, ...this.props }}>
        <Suspense fallback={null}>
          <ApolloProvider client={client}>
            <ThemeProvider theme={{ ...tokens }}>
              <ToasterProvider position="top-right">
                <Global custom={globalStyles} />
                <Routes />
              </ToasterProvider>
            </ThemeProvider>
          </ApolloProvider>
        </Suspense>
      </CustomPropsProvider>
    );
  }
}
