import React from 'react';
import { FeedbackMessage } from '@creditas/feedback-message';
import { useTranslation } from 'react-i18next';
import { ErrorMessageContainer } from './FatalError.style';

export const FatalError: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ErrorMessageContainer>
      <FeedbackMessage variant="danger">{t('fatalError')}</FeedbackMessage>
    </ErrorMessageContainer>
  );
};
