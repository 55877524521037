import { ApolloLink, Observable, Operation } from 'apollo-link';
import { authSession } from '../../../../auth-session';

const request = async (operation: Operation): Promise<void> => {
  const token = authSession.getToken();

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
};

export const addAuthTokenToRequest = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: ZenObservable.Subscription | undefined;

      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return (): void => {
        if (handle) handle.unsubscribe();
      };
    }),
);
