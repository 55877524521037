import { BFFCustomerQuery } from '../services/api/bff/types';
import { CustomerRegistrationData } from './interfaces';

const parsePhone = (code, phone): string => {
  if (!!code && !!phone) return code + phone;

  return '';
};

export const mapCustomer = (customer: BFFCustomerQuery['customer']): CustomerRegistrationData => {
  const residentialTelephone = parsePhone(
    customer.residentialTelephoneCode,
    customer.residentialTelephone,
  );
  const additionalPhone = parsePhone(customer.additionalPhoneCode, customer.additionalPhone);
  const cellphone = parsePhone(customer.cellphoneCode, customer.cellphone);
  const [address] = customer.addresses;

  return {
    customerId: customer.id,
    fullName: customer.fullName,
    email: customer.email,
    cpf: customer.cpf,
    score: customer.score || '',
    birthDate: customer.birthDate || '',
    residentialTelephone,
    additionalPhone,
    cellphone,
    monthlyIncome: customer.monthlyIncome,
    employmentStatus: customer.employment.status,
    address: {
      ...address,
      streetNumber: address.streetNumber || '',
      streetComplement: address.streetComplement || '',
      country: address.country || '',
    },
  };
};
