/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  Date: Date;
  Time: Date;
};

export type BFFQuery = {
  readonly __typename?: 'Query';
  readonly customer: BFFCustomer;
  readonly mainCustomerByLoan: BFFCustomer;
  readonly customerByPersonId: BFFCustomer;
  readonly task: BFFTask;
  readonly myTasks: ReadonlyArray<Maybe<BFFTask>>;
  readonly getAvailableChannels: ReadonlyArray<BFFChannel>;
  readonly getOverdueReasons: ReadonlyArray<BFFOverdueReason>;
  readonly consultantGroups: ReadonlyArray<Maybe<BFFConsultantGroup>>;
  readonly newLoan: Maybe<BFFNewLoan>;
  readonly person: Maybe<BFFPerson>;
};


export type BFFQueryCustomerArgs = {
  cpf: Scalars['String'];
};


export type BFFQueryMainCustomerByLoanArgs = {
  loanId: Scalars['String'];
};


export type BFFQueryCustomerByPersonIdArgs = {
  personId: Scalars['String'];
};


export type BFFQueryTaskArgs = {
  taskId: Scalars['String'];
};


export type BFFQueryMyTasksArgs = {
  active: Maybe<Scalars['Boolean']>;
};


export type BFFQueryGetAvailableChannelsArgs = {
  identifier: Maybe<BFFAvailableChannelsIdentifier>;
};


export type BFFQueryGetOverdueReasonsArgs = {
  identifier: Maybe<BFFOverdueReasonsIdentifiers>;
};


export type BFFQueryNewLoanArgs = {
  loanId: Scalars['String'];
};


export type BFFQueryPersonArgs = {
  cpf: Scalars['String'];
};

export type BFFCustomer = {
  readonly __typename?: 'Customer';
  readonly id: Scalars['ID'];
  readonly authId: Scalars['String'];
  readonly cpf: Scalars['String'];
  readonly email: Scalars['String'];
  readonly accessEmail: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly score: Maybe<Scalars['String']>;
  readonly birthDate: Maybe<Scalars['String']>;
  readonly residentialTelephoneCode: Maybe<Scalars['String']>;
  readonly residentialTelephone: Maybe<Scalars['String']>;
  readonly additionalPhoneCode: Maybe<Scalars['String']>;
  readonly additionalPhone: Maybe<Scalars['String']>;
  readonly cellphoneCode: Maybe<Scalars['String']>;
  readonly cellphone: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly addresses: ReadonlyArray<BFFAddress>;
  readonly loans: ReadonlyArray<BFFLoan>;
  readonly timeline: ReadonlyArray<Maybe<BFFTimelineItem>>;
  readonly employment: BFFEmployment;
  readonly monthlyIncome: Maybe<Scalars['Float']>;
};


export type BFFCustomerTimelineArgs = {
  filter?: Maybe<BFFTimelineFilters>;
  field?: Maybe<Scalars['String']>;
  asc?: Maybe<Scalars['Boolean']>;
};


export type BFFAddress = {
  readonly __typename?: 'Address';
  readonly id: Scalars['ID'];
  readonly streetName: Scalars['String'];
  readonly zipCode: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly streetNumber: Maybe<Scalars['String']>;
  readonly streetComplement: Maybe<Scalars['String']>;
  readonly neighborhood: Scalars['String'];
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly country: Maybe<Scalars['String']>;
};

export type BFFLoan = {
  readonly id: Scalars['ID'];
  readonly product: BFFLoanProduct;
  readonly productType: Scalars['String'];
  readonly contractMonthlyInterestRate: Scalars['Float'];
  readonly financedAmount: Scalars['Float'];
  readonly creditor: BFFCreditor;
  readonly amortizationMethod: Scalars['String'];
  readonly maturity: Scalars['Int'];
  readonly active: Scalars['Boolean'];
  readonly installments: ReadonlyArray<Maybe<BFFInstallment>>;
  readonly creditCertificateNumber: BFFCreditCertificateNumber;
  readonly contact: Maybe<BFFCommunicationContact>;
  readonly timeline: ReadonlyArray<Maybe<BFFTimelineItem>>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly lastCollectionDate: Maybe<Scalars['Date']>;
  readonly nextPromiseDueDate: Maybe<Scalars['Date']>;
};


export type BFFLoanTimelineArgs = {
  filter?: Maybe<BFFTimelineFilters>;
  field?: Maybe<Scalars['String']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export enum BFFLoanProduct {
  AUTO = 'AUTO',
  HOME = 'HOME'
}

export type BFFCreditor = {
  readonly __typename?: 'Creditor';
  readonly nameIdentifier: Scalars['String'];
  readonly businessName: Scalars['String'];
};

export type BFFInstallment = BFFBaseInstallment & {
  readonly __typename?: 'Installment';
  readonly id: Scalars['String'];
  readonly loanId: Scalars['String'];
  readonly type: BFFInstallmentType;
  readonly number: Scalars['Int'];
  readonly dueDate: Scalars['Date'];
  readonly overdueDays: Scalars['Int'];
  readonly paidAt: Maybe<Scalars['Date']>;
  readonly paidAmount: Maybe<Scalars['Float']>;
  readonly interestAmount: Scalars['Float'];
  readonly amortization: Scalars['Float'];
  readonly pmt: Scalars['Float'];
  readonly mip: Maybe<Scalars['Float']>;
  readonly dfi: Maybe<Scalars['Float']>;
  readonly servicingFee: Maybe<Scalars['Float']>;
  readonly inflationAdjustedInterest: Maybe<Scalars['Float']>;
  readonly inflationAdjustedAmortization: Maybe<Scalars['Float']>;
  readonly pmtInflationAdjusted: Maybe<Scalars['Float']>;
  readonly interestAmountInflationAdjusted: Maybe<Scalars['Float']>;
  readonly amortizationInflationAdjusted: Maybe<Scalars['Float']>;
  readonly pmtInflationAdjustment: Maybe<Scalars['Float']>;
  readonly interestAmountInflationAdjustment: Maybe<Scalars['Float']>;
  readonly amortizationInflationAdjustment: Maybe<Scalars['Float']>;
  readonly inflationIndexFactor: Maybe<Scalars['Float']>;
  readonly adjustedAt: Maybe<Scalars['Date']>;
  readonly anticipationDiscount: Maybe<Scalars['Float']>;
  readonly onDateCharges: Maybe<Scalars['Float']>;
  readonly latePaymentInterest: Maybe<Scalars['Float']>;
  readonly latePaymentPenalty: Maybe<Scalars['Float']>;
  readonly latePaymentRemuneratoryInterest: Maybe<Scalars['Float']>;
  readonly lateInflationAdjustment: Maybe<Scalars['Float']>;
  readonly latePaymentAdvancedCollectionFee: Maybe<Scalars['Float']>;
  readonly lateCharges: Maybe<Scalars['Float']>;
  readonly amountToCharge: Maybe<Scalars['Float']>;
  readonly standardDueDateInvoice: Maybe<BFFInvoice>;
  readonly renegotiatedInstallments: ReadonlyArray<Maybe<BFFChildInstallment>>;
  readonly status: Scalars['String'];
  readonly overdue: Scalars['Boolean'];
  readonly promisable: Scalars['Boolean'];
};


export type BFFInstallmentRenegotiatedInstallmentsArgs = {
  field?: Maybe<Scalars['String']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export type BFFBaseInstallment = {
  readonly id: Scalars['String'];
  readonly loanId: Scalars['String'];
  readonly type: BFFInstallmentType;
  readonly number: Scalars['Int'];
  readonly dueDate: Scalars['Date'];
  readonly overdueDays: Scalars['Int'];
  readonly paidAt: Maybe<Scalars['Date']>;
  readonly paidAmount: Maybe<Scalars['Float']>;
  readonly interestAmount: Scalars['Float'];
  readonly amortization: Scalars['Float'];
  readonly pmt: Scalars['Float'];
  readonly mip: Maybe<Scalars['Float']>;
  readonly dfi: Maybe<Scalars['Float']>;
  readonly servicingFee: Maybe<Scalars['Float']>;
  readonly inflationAdjustedInterest: Maybe<Scalars['Float']>;
  readonly inflationAdjustedAmortization: Maybe<Scalars['Float']>;
  readonly pmtInflationAdjusted: Maybe<Scalars['Float']>;
  readonly interestAmountInflationAdjusted: Maybe<Scalars['Float']>;
  readonly amortizationInflationAdjusted: Maybe<Scalars['Float']>;
  readonly pmtInflationAdjustment: Maybe<Scalars['Float']>;
  readonly interestAmountInflationAdjustment: Maybe<Scalars['Float']>;
  readonly amortizationInflationAdjustment: Maybe<Scalars['Float']>;
  readonly inflationIndexFactor: Maybe<Scalars['Float']>;
  readonly adjustedAt: Maybe<Scalars['Date']>;
  readonly anticipationDiscount: Maybe<Scalars['Float']>;
  readonly onDateCharges: Maybe<Scalars['Float']>;
  readonly latePaymentInterest: Maybe<Scalars['Float']>;
  readonly latePaymentPenalty: Maybe<Scalars['Float']>;
  readonly latePaymentRemuneratoryInterest: Maybe<Scalars['Float']>;
  readonly lateInflationAdjustment: Maybe<Scalars['Float']>;
  readonly latePaymentAdvancedCollectionFee: Maybe<Scalars['Float']>;
  readonly lateCharges: Maybe<Scalars['Float']>;
  readonly amountToCharge: Maybe<Scalars['Float']>;
  readonly standardDueDateInvoice: Maybe<BFFInvoice>;
  readonly status: Scalars['String'];
  readonly overdue: Scalars['Boolean'];
  readonly promisable: Scalars['Boolean'];
};

export enum BFFInstallmentType {
  FIXED = 'FIXED',
  INFLATION_LINKED = 'INFLATION_LINKED'
}


export type BFFInvoice = {
  readonly __typename?: 'Invoice';
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly type: BFFInvoiceTypes;
  readonly lateCharges: Scalars['Float'];
  readonly installments: ReadonlyArray<Scalars['ID']>;
  readonly paymentMethod: BFFPaymentMethod;
};

export enum BFFInvoiceTypes {
  regular = 'regular',
  eventual = 'eventual'
}

export type BFFPaymentMethod = {
  readonly id: Scalars['ID'];
  readonly paymentMethodType: BFFPaymentMethodType;
};

export enum BFFPaymentMethodType {
  boleto = 'boleto'
}

export type BFFChildInstallment = BFFBaseInstallment & {
  readonly __typename?: 'ChildInstallment';
  readonly id: Scalars['String'];
  readonly loanId: Scalars['String'];
  readonly type: BFFInstallmentType;
  readonly number: Scalars['Int'];
  readonly dueDate: Scalars['Date'];
  readonly overdueDays: Scalars['Int'];
  readonly paidAt: Maybe<Scalars['Date']>;
  readonly paidAmount: Maybe<Scalars['Float']>;
  readonly interestAmount: Scalars['Float'];
  readonly amortization: Scalars['Float'];
  readonly pmt: Scalars['Float'];
  readonly mip: Maybe<Scalars['Float']>;
  readonly dfi: Maybe<Scalars['Float']>;
  readonly servicingFee: Maybe<Scalars['Float']>;
  readonly inflationAdjustedInterest: Maybe<Scalars['Float']>;
  readonly inflationAdjustedAmortization: Maybe<Scalars['Float']>;
  readonly pmtInflationAdjusted: Maybe<Scalars['Float']>;
  readonly interestAmountInflationAdjusted: Maybe<Scalars['Float']>;
  readonly amortizationInflationAdjusted: Maybe<Scalars['Float']>;
  readonly pmtInflationAdjustment: Maybe<Scalars['Float']>;
  readonly interestAmountInflationAdjustment: Maybe<Scalars['Float']>;
  readonly amortizationInflationAdjustment: Maybe<Scalars['Float']>;
  readonly inflationIndexFactor: Maybe<Scalars['Float']>;
  readonly adjustedAt: Maybe<Scalars['Date']>;
  readonly anticipationDiscount: Maybe<Scalars['Float']>;
  readonly onDateCharges: Maybe<Scalars['Float']>;
  readonly latePaymentInterest: Maybe<Scalars['Float']>;
  readonly latePaymentPenalty: Maybe<Scalars['Float']>;
  readonly latePaymentRemuneratoryInterest: Maybe<Scalars['Float']>;
  readonly lateInflationAdjustment: Maybe<Scalars['Float']>;
  readonly latePaymentAdvancedCollectionFee: Maybe<Scalars['Float']>;
  readonly lateCharges: Maybe<Scalars['Float']>;
  readonly amountToCharge: Maybe<Scalars['Float']>;
  readonly standardDueDateInvoice: Maybe<BFFInvoice>;
  readonly status: Scalars['String'];
  readonly overdue: Scalars['Boolean'];
  readonly promisable: Scalars['Boolean'];
};

export type BFFCreditCertificateNumber = {
  readonly __typename?: 'CreditCertificateNumber';
  readonly label: Scalars['String'];
  readonly value: Scalars['String'];
};

export type BFFCommunicationContact = {
  readonly __typename?: 'CommunicationContact';
  readonly id: Scalars['ID'];
  readonly optInPhone: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  readonly isLastMessageViewedByConsultant: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly messages: ReadonlyArray<Maybe<BFFWhatsAppMessage>>;
};


export type BFFCommunicationContactMessagesArgs = {
  inbound?: Maybe<Scalars['Boolean']>;
  unread?: Maybe<Scalars['Boolean']>;
};

export type BFFWhatsAppMessage = {
  readonly __typename?: 'WhatsAppMessage';
  readonly id: Scalars['ID'];
  readonly content: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
};

export enum BFFTimelineFilters {
  billing = 'billing',
  collection = 'collection'
}

export type BFFTimelineItem = {
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly type: BFFTimelineItemType;
};

export enum BFFTimelineItemType {
  comment = 'comment',
  question = 'question',
  post_it = 'post_it',
  collection = 'collection',
  repayment_promise = 'repayment_promise',
  anticipation_request = 'anticipation_request',
  lawsuit_filling = 'lawsuit_filling',
  repossession = 'repossession',
  scheduled = 'scheduled',
  billing = 'billing'
}

export type BFFEmployment = {
  readonly __typename?: 'Employment';
  readonly status: Maybe<BFFEmploymentStatuses>;
  readonly types: ReadonlyArray<BFFEmploymentStatuses>;
};

export enum BFFEmploymentStatuses {
  freelancer = 'freelancer',
  businessman = 'businessman',
  civil_servant = 'civil_servant',
  clt = 'clt',
  retired = 'retired',
  self_employed = 'self_employed',
  unemployed = 'unemployed',
  other = 'other'
}

export type BFFTask = {
  readonly __typename?: 'Task';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly slug: BFFTaskSlug;
  readonly reference: BFFProcessReference;
  readonly assignee: Maybe<BFFAssignee>;
  readonly loan: Maybe<BFFLoan>;
  readonly customer: Maybe<BFFCustomer>;
  readonly renderStructure: BFFRenderStructure;
  /** @deprecated This is a temporary field to support tasks created by MDS */
  readonly isLegacyTask: Scalars['Boolean'];
  readonly contact: Maybe<BFFCommunicationContact>;
  readonly whatsappMessageStatus: Maybe<BFFWhatsappMessageStatus>;
  readonly demand: Maybe<BFFDemand>;
  readonly createdAt: Scalars['DateTime'];
  readonly assignmentGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly newLoan: Maybe<BFFNewLoan>;
};

export enum BFFTaskSlug {
  COLLECTION = 'COLLECTION',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  UNKNOWN = 'UNKNOWN'
}

export type BFFProcessReference = {
  readonly __typename?: 'ProcessReference';
  readonly id: Scalars['String'];
  readonly domainReference: BFFDomainReferenceName;
};

export enum BFFDomainReferenceName {
  LOAN = 'LOAN',
  UNKNOWN = 'UNKNOWN'
}

export type BFFAssignee = {
  readonly __typename?: 'Assignee';
  readonly id: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly email: Scalars['String'];
};

export type BFFRenderStructure = {
  readonly __typename?: 'RenderStructure';
  readonly mainWidgets: ReadonlyArray<Maybe<BFFMainWidget>>;
  readonly summaryWidgets: ReadonlyArray<Maybe<BFFSummaryWidget>>;
  readonly toolWidgets: ReadonlyArray<Maybe<BFFToolWidget>>;
};

export type BFFMainWidget = {
  readonly __typename?: 'MainWidget';
  readonly componentName: BFFMainComponentName;
};

export enum BFFMainComponentName {
  COLLECTION = 'COLLECTION'
}

export type BFFSummaryWidget = {
  readonly __typename?: 'SummaryWidget';
  readonly componentName: BFFSummaryComponentName;
};

export enum BFFSummaryComponentName {
  CLIENT_PROFILE = 'CLIENT_PROFILE',
  TIMELINE = 'TIMELINE'
}

export type BFFToolWidget = {
  readonly __typename?: 'ToolWidget';
  readonly componentName: BFFToolComponentName;
};

export enum BFFToolComponentName {
  LOAN_INFORMATION = 'LOAN_INFORMATION',
  CALCULATOR = 'CALCULATOR'
}

export type BFFWhatsappMessageStatus = {
  readonly __typename?: 'WhatsappMessageStatus';
  readonly contactId: Scalars['ID'];
  readonly hasUnreadMessages: Scalars['Boolean'];
  readonly lastMessageCreatedAt: Maybe<Scalars['DateTime']>;
};

export type BFFDemand = {
  readonly __typename?: 'Demand';
  readonly id: Scalars['ID'];
  readonly type: BFFTaskSlug;
  readonly referenceId: Scalars['String'];
  readonly referenceDomain: Scalars['String'];
  readonly currentState: Scalars['String'];
  readonly status: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly assignmentGroups: ReadonlyArray<Maybe<BFFAssignmentGroup>>;
  readonly customAttributes: Maybe<BFFCustomAttributes>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Maybe<Scalars['DateTime']>;
};

export type BFFAssignmentGroup = {
  readonly __typename?: 'AssignmentGroup';
  readonly name: Scalars['String'];
};

export type BFFCustomAttributes = {
  readonly type: BFFTaskSlug;
};

export type BFFNewLoan = {
  readonly __typename?: 'NewLoan';
  readonly installments: ReadonlyArray<Maybe<BFFInstallment>>;
  readonly contact: Maybe<BFFCommunicationContact>;
  readonly lastCollectionDate: Maybe<Scalars['Date']>;
  readonly nextPromiseDueDate: Maybe<Scalars['Date']>;
  readonly timeline: ReadonlyArray<Maybe<BFFTimelineItem>>;
  readonly customer: BFFCustomer;
  readonly id: Scalars['ID'];
  readonly product: BFFProduct;
  readonly financedAmount: Scalars['Float'];
  readonly creditor: Scalars['String'];
  readonly amortizationMethod: Scalars['String'];
  readonly status: Scalars['String'];
  readonly key: BFFKey;
  readonly interestRates: ReadonlyArray<BFFInterestRate>;
  readonly disbursements: ReadonlyArray<Maybe<BFFDisbursement>>;
  readonly contract: BFFContract;
  readonly taxes: ReadonlyArray<Maybe<BFFTax>>;
  readonly installmentFixedAmount: Maybe<Scalars['Float']>;
  readonly installmentsCount: Scalars['Int'];
  readonly indexation: Maybe<BFFIndexation>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly participants: ReadonlyArray<BFFParticipant>;
  readonly overdueDays: Scalars['Int'];
};


export type BFFNewLoanTimelineArgs = {
  filter?: Maybe<BFFTimelineFilters>;
  field?: Maybe<Scalars['String']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export type BFFProduct = {
  readonly __typename?: 'Product';
  readonly type: Scalars['String'];
  readonly subtype: Scalars['String'];
};

export type BFFKey = {
  readonly __typename?: 'Key';
  readonly type: Scalars['String'];
  readonly code: Scalars['String'];
};

export type BFFInterestRate = {
  readonly __typename?: 'InterestRate';
  readonly context: Scalars['String'];
  readonly frequency: Scalars['String'];
  readonly base: Scalars['Int'];
  readonly value: Scalars['Float'];
};

export type BFFDisbursement = {
  readonly __typename?: 'Disbursement';
  readonly id: Scalars['String'];
  readonly dueAt: Scalars['DateTime'];
};

export type BFFContract = {
  readonly __typename?: 'Contract';
  readonly issuedAt: Scalars['Date'];
  readonly signedAt: Scalars['Date'];
  readonly number: Scalars['String'];
};

export type BFFTax = {
  readonly __typename?: 'Tax';
  readonly type: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type BFFIndexation = {
  readonly __typename?: 'Indexation';
  readonly type: Scalars['String'];
};

export type BFFParticipant = {
  readonly id: Scalars['ID'];
  readonly authId: Scalars['String'];
  readonly creditScore: BFFCreditScore;
  readonly roles: ReadonlyArray<Scalars['String']>;
};

export type BFFCreditScore = {
  readonly __typename?: 'CreditScore';
  readonly provider: Scalars['String'];
  readonly value: Scalars['String'];
};

export enum BFFAvailableChannelsIdentifier {
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL',
  TELEPHONE = 'TELEPHONE',
  SMS = 'SMS'
}

export type BFFChannel = {
  readonly __typename?: 'Channel';
  readonly identifier: Scalars['String'];
  readonly description: Scalars['String'];
};

export enum BFFOverdueReasonsIdentifiers {
  FINANCE = 'FINANCE',
  PERSONAL = 'PERSONAL',
  FORGETFULNESS = 'FORGETFULNESS',
  PAYMENT = 'PAYMENT',
  DUE_DATE = 'DUE_DATE',
  APP = 'APP',
  CREDITAS = 'CREDITAS',
  NOT_INFORMED = 'NOT_INFORMED',
  OTHER = 'OTHER'
}

export type BFFOverdueReason = {
  readonly __typename?: 'OverdueReason';
  readonly identifier: Scalars['String'];
  readonly description: Scalars['String'];
};

export type BFFConsultantGroup = {
  readonly __typename?: 'ConsultantGroup';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly label: Scalars['String'];
  readonly consultants: ReadonlyArray<Maybe<BFFConsultant>>;
};

export type BFFConsultant = {
  readonly __typename?: 'Consultant';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly email: Scalars['String'];
};

export type BFFPerson = BFFIPerson & {
  readonly __typename?: 'Person';
  readonly loans: ReadonlyArray<Maybe<BFFNewLoan>>;
  readonly id: Scalars['ID'];
  readonly mainDocument: BFFDocument;
  readonly fullName: Scalars['String'];
  readonly birthDate: Maybe<Scalars['String']>;
  readonly contacts: ReadonlyArray<BFFContact>;
  readonly addresses: ReadonlyArray<BFFPersonAddress>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly professionalInfo: Maybe<BFFProfessionalInfo>;
  readonly accessEmail: Maybe<Scalars['String']>;
};

export type BFFIPerson = {
  readonly loans: ReadonlyArray<Maybe<BFFNewLoan>>;
  readonly id: Scalars['ID'];
  readonly mainDocument: BFFDocument;
  readonly fullName: Scalars['String'];
  readonly birthDate: Maybe<Scalars['String']>;
  readonly contacts: ReadonlyArray<BFFContact>;
  readonly addresses: ReadonlyArray<BFFPersonAddress>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly professionalInfo: Maybe<BFFProfessionalInfo>;
  readonly accessEmail: Maybe<Scalars['String']>;
};

export type BFFDocument = {
  readonly __typename?: 'Document';
  readonly type: Scalars['String'];
  readonly code: Scalars['String'];
};

export type BFFContact = {
  readonly __typename?: 'Contact';
  readonly id: Scalars['ID'];
  readonly channel: Scalars['String'];
  readonly code: Scalars['String'];
  readonly type: Maybe<Scalars['String']>;
};

export type BFFPersonAddress = {
  readonly __typename?: 'PersonAddress';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly country: Scalars['String'];
  readonly administrativeAreaLevel1: Maybe<Scalars['String']>;
  readonly administrativeAreaLevel2: Maybe<Scalars['String']>;
  readonly neighborhood: Maybe<Scalars['String']>;
  readonly street: Maybe<Scalars['String']>;
  readonly number: Maybe<Scalars['String']>;
  readonly complement: Maybe<Scalars['String']>;
  readonly zipCode: Maybe<Scalars['String']>;
};

export type BFFProfessionalInfo = {
  readonly __typename?: 'ProfessionalInfo';
  readonly jobTitle: Scalars['String'];
  readonly monthlyIncome: Scalars['Int'];
};

export type BFFMutation = {
  readonly __typename?: 'Mutation';
  readonly changeAccessEmail: BFFGenericMutationResponse;
  readonly updateCustomerProfile: BFFGenericMutationResponse;
  readonly claimNext: BFFClaimNextResponse;
  readonly completeTask: BFFGenericMutationResponse;
  readonly startCollection: BFFCollectionResponse;
  /** @deprecated This is a legacy mutation. Use one of 'finishCollectionWithoutContact' or 'finishCollectionWithoutPromise' instead. */
  readonly finishCollection: BFFCollectionResponse;
  /** @deprecated This is a legacy mutation. Use 'finishCollectionWithPromise' instead. */
  readonly addPromiseToCollection: BFFPromiseResponse;
  readonly finishCollectionWithoutContact: BFFCollectionResponse;
  readonly finishCollectionWithoutPromise: BFFCollectionResponse;
  readonly finishCollectionWithPromise: BFFCollectionPromiseResponse;
  readonly finishCollectionWithScheduling: BFFCollectionSchedulingResponse;
  readonly deleteConsultantFromGroup: Maybe<BFFDeleteConsultantGroupsResponse>;
  readonly addConsultantToGroup: Maybe<BFFAddConsultantGroupsResponse>;
  readonly updateFixedComment: BFFGenericMutationResponse;
  readonly createComment: BFFCreatedCommentResponse;
};


export type BFFMutationChangeAccessEmailArgs = {
  cpf: Scalars['String'];
  newEmail: Scalars['String'];
  reason: Scalars['String'];
  currentEmail: Scalars['String'];
};


export type BFFMutationUpdateCustomerProfileArgs = {
  customerId: Scalars['String'];
  cpf: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  fullName: Maybe<Scalars['String']>;
  score: Maybe<Scalars['String']>;
  birthDate: Maybe<Scalars['Date']>;
  residentialTelephoneCode: Maybe<Scalars['String']>;
  residentialTelephone: Maybe<Scalars['String']>;
  additionalPhoneCode: Maybe<Scalars['String']>;
  additionalPhone: Maybe<Scalars['String']>;
  cellphoneCode: Maybe<Scalars['String']>;
  cellphone: Maybe<Scalars['String']>;
  monthlyIncome: Maybe<Scalars['Float']>;
  employmentStatus: Maybe<BFFEmploymentStatuses>;
  address: Maybe<BFFAddressInput>;
};


export type BFFMutationClaimNextArgs = {
  taskType: Maybe<BFFTaskTypeFilter>;
};


export type BFFMutationCompleteTaskArgs = {
  taskId: Scalars['String'];
  variables: BFFCompleteTaskVariables;
};


export type BFFMutationStartCollectionArgs = {
  loanId: Scalars['ID'];
  channel?: Maybe<Scalars['String']>;
};


export type BFFMutationFinishCollectionArgs = {
  collectionId: Scalars['ID'];
  comment: Scalars['String'];
  channel: Scalars['String'];
  success: Scalars['Boolean'];
  inboundContact: Maybe<Scalars['Boolean']>;
  overdueReason: Maybe<Scalars['String']>;
};


export type BFFMutationAddPromiseToCollectionArgs = {
  collectionId: Scalars['String'];
  installmentsIds: ReadonlyArray<Scalars['ID']>;
  dueDate: Scalars['Date'];
};


export type BFFMutationFinishCollectionWithoutContactArgs = {
  collectionId: Scalars['ID'];
  comment: Scalars['String'];
  channel: Scalars['String'];
};


export type BFFMutationFinishCollectionWithoutPromiseArgs = {
  collectionId: Scalars['ID'];
  comment: Scalars['String'];
  channel: Scalars['String'];
  inboundContact: Scalars['Boolean'];
  overdueReason: Scalars['String'];
};


export type BFFMutationFinishCollectionWithPromiseArgs = {
  collectionId: Scalars['ID'];
  installmentsIds: ReadonlyArray<Scalars['ID']>;
  dueDate: Scalars['Date'];
  comment: Scalars['String'];
  channel: Scalars['String'];
  inboundContact: Scalars['Boolean'];
  overdueReason: Scalars['String'];
};


export type BFFMutationFinishCollectionWithSchedulingArgs = {
  loanId: Scalars['String'];
  collectionId: Scalars['String'];
  channel: Scalars['String'];
  comment: Scalars['String'];
  inboundContact: Scalars['Boolean'];
  overdueReason: Scalars['String'];
  triggersAt: Scalars['DateTime'];
};


export type BFFMutationDeleteConsultantFromGroupArgs = {
  groupId: Scalars['ID'];
  consultantId: Scalars['ID'];
};


export type BFFMutationAddConsultantToGroupArgs = {
  groupId: Scalars['ID'];
  email: Scalars['String'];
};


export type BFFMutationUpdateFixedCommentArgs = {
  personId: Scalars['String'];
  comment: Scalars['String'];
  consultantEmail: Maybe<Scalars['String']>;
};


export type BFFMutationCreateCommentArgs = {
  loanId: Scalars['ID'];
  comment: Scalars['String'];
};

export type BFFGenericMutationResponse = {
  readonly __typename?: 'genericMutationResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
};

export type BFFAddressInput = {
  readonly streetName: Scalars['String'];
  readonly zipCode: Scalars['String'];
  readonly streetNumber: Maybe<Scalars['String']>;
  readonly streetComplement: Maybe<Scalars['String']>;
  readonly neighborhood: Scalars['String'];
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly country: Scalars['String'];
};

export enum BFFTaskTypeFilter {
  INBOUND_COMMUNICATION = 'INBOUND_COMMUNICATION',
  OUTBOUND_COMMUNICATION = 'OUTBOUND_COMMUNICATION'
}

export type BFFClaimNextResponse = {
  readonly __typename?: 'ClaimNextResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly claimedTask: Maybe<BFFTask>;
};

export type BFFCompleteTaskVariables = {
  readonly taskType: BFFTaskTypeInputEnum;
  readonly collection: Maybe<BFFCollectionTaskVariables>;
  readonly customerService: Maybe<BFFCustomerServiceTaskVariables>;
};

export enum BFFTaskTypeInputEnum {
  collection = 'collection',
  customerService = 'customerService'
}

export type BFFCollectionTaskVariables = {
  readonly contactSuccessful: Scalars['Boolean'];
  readonly promiseSuccessful: Scalars['Boolean'];
  readonly hasScheduledContactDate: Maybe<Scalars['Boolean']>;
  readonly promiseDueDate: Maybe<Scalars['Date']>;
  readonly schedulingDueDate: Maybe<Scalars['Date']>;
  readonly actionableTask: Maybe<Scalars['Boolean']>;
};

export type BFFCustomerServiceTaskVariables = {
  readonly customerServiceCompleted: Maybe<Scalars['Boolean']>;
};

export type BFFCollectionResponse = {
  readonly __typename?: 'CollectionResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly collection: BFFCollection;
};

export type BFFCollection = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'Collection';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly product: Maybe<Scalars['String']>;
  readonly comment: Maybe<Scalars['String']>;
  readonly channel: Scalars['String'];
  readonly contactSuccess: Scalars['Boolean'];
  readonly returnContact: Scalars['Boolean'];
  /** @deprecated This variable is incorrectly used for reasons besides a contact failure. Use `contactDetail` instead. */
  readonly contactFailureReason: Maybe<Scalars['String']>;
  readonly contactDetail: Maybe<Scalars['String']>;
  readonly overdueReason: Maybe<Scalars['String']>;
  readonly selfClosing: Scalars['Boolean'];
  readonly inboundContact: Scalars['Boolean'];
};

export type BFFCollectionTimelineItem = {
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  /** @deprecated Calendar scheduler items don't have `product` field. */
  readonly product: Maybe<Scalars['String']>;
  /** @deprecated Only some items have the `channel` field. */
  readonly channel: Maybe<Scalars['String']>;
};

export type BFFTimelineConsultant = {
  readonly __typename?: 'TimelineConsultant';
  readonly name: Scalars['String'];
  readonly email: Scalars['String'];
};

export type BFFPromiseResponse = {
  readonly __typename?: 'PromiseResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly promise: BFFRepaymentPromise;
};

export type BFFRepaymentPromise = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'RepaymentPromise';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly product: Maybe<Scalars['String']>;
  readonly dueDate: Maybe<Scalars['Date']>;
  readonly promisedInstallments: ReadonlyArray<Maybe<BFFPromisedInstallments>>;
  /** @deprecated This type does not have the `channel` field. */
  readonly channel: Maybe<Scalars['String']>;
};


export type BFFRepaymentPromisePromisedInstallmentsArgs = {
  sortBy?: Maybe<ReadonlyArray<ReadonlyArray<Scalars['String']>>>;
};

export type BFFPromisedInstallments = {
  readonly __typename?: 'PromisedInstallments';
  readonly id: Scalars['ID'];
  readonly number: Scalars['Int'];
  readonly overdue: Scalars['Boolean'];
  readonly isChild: Scalars['Boolean'];
  readonly totalRenegotiatedInstallments: Scalars['Int'];
  readonly renegotiatedInstallmentId: Maybe<Scalars['ID']>;
  readonly renegotiatedInstallmentNumber: Maybe<Scalars['Int']>;
};

export type BFFCollectionPromiseResponse = {
  readonly __typename?: 'CollectionPromiseResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly promise: BFFRepaymentPromise;
  readonly collection: BFFCollection;
};

export type BFFCollectionSchedulingResponse = {
  readonly __typename?: 'CollectionSchedulingResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly scheduling: Maybe<BFFScheduled>;
};

export type BFFScheduled = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'Scheduled';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly comment: Maybe<Scalars['String']>;
  readonly scheduledTo: Maybe<Scalars['DateTime']>;
  /** @deprecated Calendar scheduler items don't have `product` field. */
  readonly product: Maybe<Scalars['String']>;
  /** @deprecated This type does not have the `channel` field. */
  readonly channel: Maybe<Scalars['String']>;
};

export type BFFDeleteConsultantGroupsResponse = {
  readonly __typename?: 'DeleteConsultantGroupsResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly consultants: ReadonlyArray<Maybe<BFFConsultant>>;
};

export type BFFAddConsultantGroupsResponse = {
  readonly __typename?: 'AddConsultantGroupsResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly consultant: BFFConsultant;
};

export type BFFCreatedCommentResponse = {
  readonly __typename?: 'CreatedCommentResponse';
  readonly code: Scalars['Int'];
  readonly success: Scalars['Boolean'];
  readonly message: Scalars['String'];
  readonly comment: Maybe<BFFCreatedComment>;
};

export type BFFCreatedComment = {
  readonly __typename?: 'CreatedComment';
  readonly id: Scalars['ID'];
  readonly consultant: BFFTimelineConsultant;
  readonly comment: Scalars['String'];
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Scalars['DateTime'];
};


export type BFFAutoLoan = BFFLoan & {
  readonly __typename?: 'AutoLoan';
  readonly id: Scalars['ID'];
  readonly product: BFFLoanProduct;
  readonly productType: Scalars['String'];
  readonly contractMonthlyInterestRate: Scalars['Float'];
  readonly periodMonthlyInterestRate: Scalars['Float'];
  readonly financedAmount: Scalars['Float'];
  readonly creditor: BFFCreditor;
  readonly amortizationMethod: Scalars['String'];
  readonly maturity: Scalars['Int'];
  readonly paidAt: Maybe<Scalars['Date']>;
  readonly active: Scalars['Boolean'];
  readonly installments: ReadonlyArray<Maybe<BFFInstallment>>;
  readonly creditCertificateNumber: BFFCreditCertificateNumber;
  readonly contact: Maybe<BFFCommunicationContact>;
  readonly timeline: ReadonlyArray<Maybe<BFFTimelineItem>>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly lastCollectionDate: Maybe<Scalars['Date']>;
  readonly nextPromiseDueDate: Maybe<Scalars['Date']>;
};


export type BFFAutoLoanInstallmentsArgs = {
  field?: Maybe<Scalars['String']>;
  asc?: Maybe<Scalars['Boolean']>;
};


export type BFFAutoLoanTimelineArgs = {
  filter?: Maybe<BFFTimelineFilters>;
  field?: Maybe<Scalars['String']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export type BFFHomeLoan = BFFLoan & {
  readonly __typename?: 'HomeLoan';
  readonly id: Scalars['ID'];
  readonly product: BFFLoanProduct;
  readonly productType: Scalars['String'];
  readonly contractMonthlyInterestRate: Scalars['Float'];
  readonly financedAmount: Scalars['Float'];
  readonly creditor: BFFCreditor;
  readonly amortizationMethod: Scalars['String'];
  readonly maturity: Scalars['Int'];
  readonly referenceIndexType: Maybe<Scalars['String']>;
  readonly active: Scalars['Boolean'];
  readonly installments: ReadonlyArray<Maybe<BFFInstallment>>;
  readonly creditCertificateNumber: BFFCreditCertificateNumber;
  readonly contact: Maybe<BFFCommunicationContact>;
  readonly timeline: ReadonlyArray<Maybe<BFFTimelineItem>>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly lastCollectionDate: Maybe<Scalars['Date']>;
  readonly nextPromiseDueDate: Maybe<Scalars['Date']>;
};


export type BFFHomeLoanInstallmentsArgs = {
  field?: Maybe<Scalars['String']>;
  asc?: Maybe<Scalars['Boolean']>;
};


export type BFFHomeLoanTimelineArgs = {
  filter?: Maybe<BFFTimelineFilters>;
  field?: Maybe<Scalars['String']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export enum BFFBoletoStatus {
  processing = 'processing',
  registered = 'registered',
  rejected = 'rejected'
}

export enum BFFBoletoType {
  ordinary = 'ordinary',
  extraordinary = 'extraordinary'
}

export type BFFBoleto = BFFPaymentMethod & {
  readonly __typename?: 'Boleto';
  readonly id: Scalars['ID'];
  readonly paymentMethodType: BFFPaymentMethodType;
  readonly amount: Scalars['Float'];
  readonly dueDate: Scalars['Date'];
  readonly expired: Scalars['Boolean'];
  readonly status: BFFBoletoStatus;
  readonly type: BFFBoletoType;
  readonly barcode: Maybe<Scalars['String']>;
  readonly link: Maybe<Scalars['String']>;
};

export type BFFComment = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'Comment';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly product: Maybe<Scalars['String']>;
  /** @deprecated This type does not have the `channel` field. */
  readonly channel: Maybe<Scalars['String']>;
  readonly contents: Scalars['String'];
};

export type BFFQuestion = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'Question';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly product: Maybe<Scalars['String']>;
  readonly contents: Scalars['String'];
  readonly inboundContact: Scalars['Boolean'];
  readonly channel: Scalars['String'];
};

export type BFFPostIt = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'PostIt';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly product: Maybe<Scalars['String']>;
  /** @deprecated This type does not have the `channel` field. */
  readonly channel: Maybe<Scalars['String']>;
  readonly contents: Scalars['String'];
};

export type BFFAnticipationRequest = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'AnticipationRequest';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly product: Maybe<Scalars['String']>;
  readonly comment: Maybe<Scalars['String']>;
  readonly reason: Maybe<Scalars['String']>;
  readonly dueDate: Maybe<Scalars['Date']>;
  readonly fullRepayment: Scalars['Boolean'];
  readonly channel: Scalars['String'];
};

export type BFFLawsuitFilling = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'LawsuitFilling';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly product: Maybe<Scalars['String']>;
  readonly comment: Maybe<Scalars['String']>;
  readonly fillingDate: Maybe<Scalars['Date']>;
  readonly active: Scalars['Boolean'];
  /** @deprecated This type does not have the `channel` field. */
  readonly channel: Maybe<Scalars['String']>;
};

export type BFFRepossession = BFFCollectionTimelineItem & BFFTimelineItem & {
  readonly __typename?: 'Repossession';
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
  readonly consultant: Maybe<BFFTimelineConsultant>;
  readonly createdAt: Scalars['DateTime'];
  readonly finishedAt: Maybe<Scalars['DateTime']>;
  readonly type: BFFTimelineItemType;
  readonly product: Maybe<Scalars['String']>;
  readonly comment: Maybe<Scalars['String']>;
  readonly fillingDate: Maybe<Scalars['Date']>;
  /** @deprecated This type does not have the `channel` field. */
  readonly channel: Maybe<Scalars['String']>;
};

export type BFFBillingDelay = BFFTimelineItem & {
  readonly __typename?: 'BillingDelay';
  readonly paidAt: Maybe<Scalars['String']>;
  readonly overdueDays: Scalars['Int'];
  readonly number: Scalars['Int'];
  readonly installmentDueDate: Scalars['String'];
  readonly type: BFFTimelineItemType;
  readonly id: Scalars['ID'];
  readonly loanId: Scalars['ID'];
};

export type BFFCollectionCustomAttributes = BFFCustomAttributes & {
  readonly __typename?: 'CollectionCustomAttributes';
  readonly type: BFFTaskSlug;
  readonly product: Scalars['String'];
  readonly productType: Scalars['String'];
  readonly repaymentBehaviorScore: Scalars['Float'];
  readonly overdueDays: Scalars['Int'];
  readonly lastUnhandledChatMessageReceivedAt: Maybe<Scalars['DateTime']>;
  readonly schedulingStatus: Maybe<Scalars['String']>;
  readonly renegotiationTypes: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly segmentationGroup: Maybe<Scalars['String']>;
};

export type BFFCustomerServiceCustomAttributes = BFFCustomAttributes & {
  readonly __typename?: 'CustomerServiceCustomAttributes';
  readonly type: BFFTaskSlug;
  readonly ticketId: Maybe<Scalars['Int']>;
};

export type BFFPersonParticipant = BFFParticipant & BFFIPerson & {
  readonly __typename?: 'PersonParticipant';
  readonly loans: ReadonlyArray<Maybe<BFFNewLoan>>;
  readonly fixedComment: Maybe<BFFFixedComment>;
  readonly id: Scalars['ID'];
  readonly authId: Scalars['String'];
  readonly creditScore: BFFCreditScore;
  readonly roles: ReadonlyArray<Scalars['String']>;
  readonly mainDocument: BFFDocument;
  readonly fullName: Scalars['String'];
  readonly birthDate: Maybe<Scalars['String']>;
  readonly contacts: ReadonlyArray<BFFContact>;
  readonly addresses: ReadonlyArray<BFFPersonAddress>;
  readonly createdAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly professionalInfo: Maybe<BFFProfessionalInfo>;
  readonly accessEmail: Maybe<Scalars['String']>;
};

export type BFFFixedComment = {
  readonly __typename?: 'FixedComment';
  readonly id: Scalars['String'];
  readonly comment: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly consultant: BFFTimelineConsultant;
};


export const UpdateCustomerProfileDocument = gql`
    mutation UpdateCustomerProfile($customerId: String!, $cpf: String, $email: String, $fullName: String, $score: String, $birthDate: Date, $residentialTelephoneCode: String, $residentialTelephone: String, $additionalPhoneCode: String, $additionalPhone: String, $cellphoneCode: String, $cellphone: String, $monthlyIncome: Float, $employmentStatus: EmploymentStatuses, $address: AddressInput) {
  updateCustomerProfile(
    customerId: $customerId
    cpf: $cpf
    email: $email
    fullName: $fullName
    score: $score
    birthDate: $birthDate
    residentialTelephoneCode: $residentialTelephoneCode
    residentialTelephone: $residentialTelephone
    additionalPhoneCode: $additionalPhoneCode
    additionalPhone: $additionalPhone
    cellphoneCode: $cellphoneCode
    cellphone: $cellphone
    monthlyIncome: $monthlyIncome
    employmentStatus: $employmentStatus
    address: $address
  ) {
    code
    success
    message
  }
}
    `;
export type BFFUpdateCustomerProfileMutationFn = ApolloReactCommon.MutationFunction<BFFUpdateCustomerProfileMutation, BFFUpdateCustomerProfileMutationVariables>;

/**
 * __useUpdateCustomerProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerProfileMutation, { data, loading, error }] = useUpdateCustomerProfileMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      cpf: // value for 'cpf'
 *      email: // value for 'email'
 *      fullName: // value for 'fullName'
 *      score: // value for 'score'
 *      birthDate: // value for 'birthDate'
 *      residentialTelephoneCode: // value for 'residentialTelephoneCode'
 *      residentialTelephone: // value for 'residentialTelephone'
 *      additionalPhoneCode: // value for 'additionalPhoneCode'
 *      additionalPhone: // value for 'additionalPhone'
 *      cellphoneCode: // value for 'cellphoneCode'
 *      cellphone: // value for 'cellphone'
 *      monthlyIncome: // value for 'monthlyIncome'
 *      employmentStatus: // value for 'employmentStatus'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdateCustomerProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BFFUpdateCustomerProfileMutation, BFFUpdateCustomerProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<BFFUpdateCustomerProfileMutation, BFFUpdateCustomerProfileMutationVariables>(UpdateCustomerProfileDocument, baseOptions);
      }
export type UpdateCustomerProfileMutationHookResult = ReturnType<typeof useUpdateCustomerProfileMutation>;
export type UpdateCustomerProfileMutationResult = ApolloReactCommon.MutationResult<BFFUpdateCustomerProfileMutation>;
export type UpdateCustomerProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<BFFUpdateCustomerProfileMutation, BFFUpdateCustomerProfileMutationVariables>;
export const CustomerDocument = gql`
    query Customer($personId: String!) {
  customerByPersonId(personId: $personId) {
    id
    cpf
    email
    fullName
    score
    birthDate
    residentialTelephoneCode
    residentialTelephone
    additionalPhoneCode
    additionalPhone
    cellphoneCode
    cellphone
    monthlyIncome
    employment {
      status
    }
    addresses {
      streetName
      streetNumber
      streetComplement
      zipCode
      city
      state
      neighborhood
      country
    }
  }
}
    `;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BFFCustomerQuery, BFFCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<BFFCustomerQuery, BFFCustomerQueryVariables>(CustomerDocument, baseOptions);
      }
export function useCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BFFCustomerQuery, BFFCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BFFCustomerQuery, BFFCustomerQueryVariables>(CustomerDocument, baseOptions);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<BFFCustomerQuery, BFFCustomerQueryVariables>;
export type BFFUpdateCustomerProfileMutationVariables = {
  customerId: Scalars['String'];
  cpf: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  fullName: Maybe<Scalars['String']>;
  score: Maybe<Scalars['String']>;
  birthDate: Maybe<Scalars['Date']>;
  residentialTelephoneCode: Maybe<Scalars['String']>;
  residentialTelephone: Maybe<Scalars['String']>;
  additionalPhoneCode: Maybe<Scalars['String']>;
  additionalPhone: Maybe<Scalars['String']>;
  cellphoneCode: Maybe<Scalars['String']>;
  cellphone: Maybe<Scalars['String']>;
  monthlyIncome: Maybe<Scalars['Float']>;
  employmentStatus: Maybe<BFFEmploymentStatuses>;
  address: Maybe<BFFAddressInput>;
};


export type BFFUpdateCustomerProfileMutation = (
  { readonly __typename?: 'Mutation' }
  & { readonly updateCustomerProfile: (
    { readonly __typename?: 'genericMutationResponse' }
    & Pick<BFFGenericMutationResponse, 'code' | 'success' | 'message'>
  ) }
);

export type BFFCustomerQueryVariables = {
  personId: Scalars['String'];
};


export type BFFCustomerQuery = (
  { readonly __typename?: 'Query' }
  & { readonly customerByPersonId: (
    { readonly __typename?: 'Customer' }
    & Pick<BFFCustomer, 'id' | 'cpf' | 'email' | 'fullName' | 'score' | 'birthDate' | 'residentialTelephoneCode' | 'residentialTelephone' | 'additionalPhoneCode' | 'additionalPhone' | 'cellphoneCode' | 'cellphone' | 'monthlyIncome'>
    & { readonly employment: (
      { readonly __typename?: 'Employment' }
      & Pick<BFFEmployment, 'status'>
    ), readonly addresses: ReadonlyArray<(
      { readonly __typename?: 'Address' }
      & Pick<BFFAddress, 'streetName' | 'streetNumber' | 'streetComplement' | 'zipCode' | 'city' | 'state' | 'neighborhood' | 'country'>
    )> }
  ) }
);


      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "Loan",
        "possibleTypes": [
          {
            "name": "AutoLoan"
          },
          {
            "name": "HomeLoan"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BaseInstallment",
        "possibleTypes": [
          {
            "name": "Installment"
          },
          {
            "name": "ChildInstallment"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "PaymentMethod",
        "possibleTypes": [
          {
            "name": "Boleto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "TimelineItem",
        "possibleTypes": [
          {
            "name": "Collection"
          },
          {
            "name": "RepaymentPromise"
          },
          {
            "name": "Scheduled"
          },
          {
            "name": "Comment"
          },
          {
            "name": "Question"
          },
          {
            "name": "PostIt"
          },
          {
            "name": "AnticipationRequest"
          },
          {
            "name": "LawsuitFilling"
          },
          {
            "name": "Repossession"
          },
          {
            "name": "BillingDelay"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CustomAttributes",
        "possibleTypes": [
          {
            "name": "CollectionCustomAttributes"
          },
          {
            "name": "CustomerServiceCustomAttributes"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Participant",
        "possibleTypes": [
          {
            "name": "PersonParticipant"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "IPerson",
        "possibleTypes": [
          {
            "name": "Person"
          },
          {
            "name": "PersonParticipant"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CollectionTimelineItem",
        "possibleTypes": [
          {
            "name": "Collection"
          },
          {
            "name": "RepaymentPromise"
          },
          {
            "name": "Scheduled"
          },
          {
            "name": "Comment"
          },
          {
            "name": "Question"
          },
          {
            "name": "PostIt"
          },
          {
            "name": "AnticipationRequest"
          },
          {
            "name": "LawsuitFilling"
          },
          {
            "name": "Repossession"
          }
        ]
      }
    ]
  }
};
      export default result;
    