import React from 'react';
import { styled } from '@creditas/stylitas';
import { File } from '@creditas/icons';
import { animation, loadingIcon, wrapper } from './Loader.style';

const Wrapper = styled.div`
  ${wrapper}
  ${animation}
`;

const Icon = styled(File.Loader)`
  ${loadingIcon}
`;

export const Loader: React.FC = () => {
  return (
    <Wrapper data-testid="loader">
      <Icon />
    </Wrapper>
  );
};
