import { useApolloClient } from '@apollo/react-hooks';

type AccessToken = string;

const localStorageKeys = {
  ACCESS_TOKEN: 'greencare-access-token',
  USER: 'greencare-user',
};

const getToken = (): Maybe<AccessToken> => localStorage.getItem(localStorageKeys.ACCESS_TOKEN);

const logout = (): void => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useApolloClient();

  client.clearStore();

  localStorage.removeItem(localStorageKeys.USER);
  localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
};

export const authSession = {
  getToken,
  logout,
};
