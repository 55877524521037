import { css, keyframes } from '@creditas/stylitas';
import tokens from '@creditas/tokens';

const spin = keyframes`
  100% {
    transform:rotate(360deg);
  }
`;

export const animation = () => css`
  animation: ${spin} 4s linear infinite;
`;

// TODO remove hardcoded pixels
export const loadingIcon = () => css`
  width: 5em;
  height: 5em;
  fill: ${tokens.bColorneutral05};
`;

export const wrapper = () => css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
