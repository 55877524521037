import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { envVars } from '../../../envVars';
import { cache, addAuthTokenToRequest, addIdToRequest } from './config';

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    addAuthTokenToRequest,
    addIdToRequest,
    new HttpLink({
      uri: envVars.REACT_APP_BFF_URL,
    }),
  ]),
});

export { client };
