import { BrowserClient, Hub } from '@sentry/browser';
import { envVars } from '../envVars';

export class Sentry {
  static hub: Hub;

  static init(): void {
    if (this.hub) return;

    try {
      const client = new BrowserClient({
        dsn: envVars.SENTRY_DSN,
        environment: envVars.REACT_APP_ENV,
        debug: envVars.REACT_APP_ENV !== 'production',
      });
      this.hub = new Hub(client);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }

  static captureException(exception: string): string {
    return this.hub.captureException(exception);
  }

  static captureMessage(message: string): string {
    return this.hub.captureMessage(message);
  }
}
