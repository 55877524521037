import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useTranslation } from '@creditas/i18n';
import { CustomPropsContext } from './contexts/CustomPropsContext';
import { CustomerRegistrationContainer } from './CustomerRegistration';

export const Routes = (): React.ReactElement => {
  const { t } = useTranslation();
  const { basePath } = useContext(CustomPropsContext);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={`${basePath}/${t('routes.customerRegistration')}`}
          component={CustomerRegistrationContainer}
        />
      </Switch>
    </BrowserRouter>
  );
};
