import { styled, utilities, css, mq } from '@creditas/stylitas';
import { Card } from '@creditas/card';

const layout = ({ theme }: any) => css`
  flex-direction: column;
  padding: 40px;
  background-color: ${utilities.palette.color(theme, 'neutral02')};
`;

const divider = ({ theme }: any) => css`
  border-bottom: 1px solid ${utilities.palette.color(theme, 'neutral03')};
  margin: 8px 0 30px 0;
`;

const displayFlex = css`
  display: flex;
`;

const grid = {
  display: 'grid',
  gap: [0, null, null, '0 45px', '0 100px'],
};

export const saveButton = () => css`
  align-self: flex-end;
  margin-top: 24px;
`;

export const Layout = styled.section`
  ${displayFlex};
  ${layout};
`;

export const Header = styled.header`
  ${displayFlex};
  flex-direction: column;
  margin-bottom: 24px;
`;

export const PageWrapper = styled(Card)`
  padding: 26px;
`;

export const Divider = styled.div`
  ${divider};
`;

export const Form = styled.form`
  ${displayFlex}
  flex-direction: column;
`;

export const TextFieldWrapper = styled.div`
  ${mq({
    ...grid,
    gridTemplateColumns: ['1fr', null, null, '385px repeat(2, 170px)', '440px repeat(2, 170px)'],
  })}
`;

export const TextFieldPhones = styled.div`
  ${mq({
    ...grid,
    gridRow: 3,
    gridTemplateColumns: ['1fr', null, null, 'repeat(3, 170px)'],
  })}
`;

export const TextFieldZipCode = styled.div`
  ${mq({
    width: ['auto', null, null, '170px'],
  })}
`;

export const SaveButton = styled.div`
  ${saveButton};
`;
