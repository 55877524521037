const bffUrl = window.location.hostname.includes('creditas.com')
  ? process.env.REACT_APP_BFF_URL
  : process.env.REACT_APP_BFF_VPN_URL;

export const envVars = {
  APP_NAME: 'customer-registration',
  PUBLIC_URL: process.env.PUBLIC_URL || '',
  REACT_APP_ENV: process.env.REACT_APP_ENV || '',
  PORT: process.env.PORT || '',
  SKIP_PREFLIGHT_CHECK: process.env.SKIP_PREFLIGHT_CHECK || '',
  SENTRY_DSN: process.env.SENTRY_DSN || '',
  GREENCARE_URL: process.env.GREENCARE_URL || '',
  REACT_APP_BFF_URL: bffUrl || '',
  REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  REACT_APP_DATADOG_APPLICATION_ID: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
  REACT_APP_DATADOG_VERSION: process.env.CIRCLE_SHA1 || '',
  REACT_APP_DATADOG_SERVICE: process.env.REACT_APP_DATADOG_SERVICE || '',
};
